<template>
  <div id="app">
    <div class="app_version">ver: {{ version }}</div>
    <the-navigation />
    <router-view />
    <the-loader />
  </div>
</template>

<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import TheNavigation from './components/TheNavigation.vue'
import TheLoader from './components/TheLoader.vue'
import { version } from '../package.json'

export default {
  name: 'App',
  components: { TheNavigation, TheLoader },
  created() {
    this.$socket.onmessage = (event) => {
      this.$emitter.emit('socketMessage', event)
    }
  },
  data() {
    return {
      version: null
    }
  },
  mounted() {
    this.version = version
    // this.$emitter.on('socketMessage', async (event) => {
    //   const data = event.data
    //   const dataJson = JSON.parse(data)
    //   const payload = dataJson.payload
    //   const payloadData = payload.data
    //   if (payload.event === 'activity_process_complete') {
    //     this.$emitter.emit('ag_grid_update_row', {
    //       _id: payloadData.activity_id,
    //       data: await MtApi.getActivity(payloadData.activity_id)
    //     })
    //     this.$emitter.emit('activities_process_remaining_count', {
    //       platform: payloadData.platform,
    //       count: payloadData.remaining_activities
    //     })
    //   } else if (payload.event === 'api_error') {
    //     this.$bvToast.toast(payloadData.message, {
    //       title: payloadData.title,
    //       variant: 'danger',
    //       solid: true
    //     })
    //   } else if (payload.event === 'journal_update') {
    //     this.$emitter.emit('journal_update', {})
    //   } else if (payload.event === 'forms_update') {
    //     this.$emitter.emit('forms_update', {
    //       platform_id: payloadData.platform_id
    //     })
    //   } else {
    //     this.$emitter.emit(payload.event, payloadData)
    //   }
    // })
  }
}
</script>

<style lang="scss">
.container-full-width {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 60px;
}
.ag-row {
  &.row-red-outer {
    background-color: #ff0000;
    color: white;
    font-weight: 600;
  }
  &.row-transparent-outer {
    // background-color: #d7d7d7;
    .select-rendrer .v-select-empty .vs__dropdown-toggle {
      background-color: #EFEFEF;
    }
    .select-rendrer .v-select-empty .vs__dropdown-toggle .vs__search {
      color: #191919;
    }
    .select-rendrer .v-select-empty .vs__dropdown-toggle svg path {
      fill: rgba(60, 60, 60, 0.5);
    }
  }
}
.projects-nav-elements {
  display: flex;

  > *:not(:last-child) {
    margin-right: 20px;
  }

  .showStatus {
    margin-left: 5px;
  }
}
.ag-grid-container {
  height: 90vh;
}
.ag-theme-alpine {
  padding-bottom: 30px;
}
.ag-table {
  width: 100%;
  height: 100%;
}
.ag-fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  width: 100% !important;
  max-width: initial !important;
  height: auto !important;
  margin: 0 !important;
  padding-top: 15px;
  padding-bottom: 75px !important;
}
.ag-fullScreen-app .projects-nav-elements {
  display: none;
}
#app .ag-theme-alpine {
  --ag-odd-row-background-color: #fff;
}
.filterChanged,
.showTest,
.hideArchives {
  label {
    margin-bottom: 0;
  }
}
.part-platform-selector .custom-selector .vs__dropdown-toggle {
  border-radius: 0;
}
.app_version {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 10px;
}
</style>
